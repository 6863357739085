body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 768px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  overflow-x: hidden;
}

.ant-tour-header, .ant-tour-description {
  padding-right: 32px!important;
}

.ant-tour-close {
  inset-inline-end: 10px!important;
}

.ant-card {
  border: none;
}

.ant-message {
  z-index: 2100;
}

.ant-dropdown-menu {
  max-width: 365px;
}

.notifications-dropdown li {
  cursor: default !important;
}

